import { XCircleIcon } from "@heroicons/react/solid";
import { Component } from "react";
import * as Sentry from "@sentry/react";


const fallbackUI = (error) => {
  
}
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {error: ""};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error: `${error.name}: ${error.message}`});
    Sentry.captureException(error);
  }

  render() {
    const {error} = this.state;
    if (error) {
      return (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">An error occured.</h3>
              <div className="mt-2 text-sm text-red-700">
                {error}
              </div>
            </div>
          </div>
        </div>
     
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}