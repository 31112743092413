import '../styles/globals.css'
import '../styles/animations.css'

import '../styles/button.css';
import 'react-toastify/dist/ReactToastify.css';

import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import '../styles/schedule.css';
import "flatpickr/dist/themes/dark.css";

import { IntercomProvider, useIntercom } from 'react-use-intercom';
const INTERCOM_APP_ID = 'zlflo3lm';

import type { AppProps } from 'next/app'
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { getJWT } from '@/lib/Auth';
import App from 'next/app'

import { getTokenFromContext } from '@/lib/Auth'
const API_URL = process.env.NEXT_PUBLIC_API_URL;

import Pusher from 'pusher-js';
import { PusherProvider } from "@harelpls/use-pusher";

import NextNProgress from 'nextjs-progressbar';
import Head from 'next/head';
import { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ErrorBoundary from '@/components/ErrorBoundary';

Sentry.init({
  dsn: "https://1b004e7c1ee540dbadc460c165ba039e@o926374.ingest.sentry.io/5875742",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});



function MyApp({ Component, pageProps }: AppProps) {
  const token = pageProps.authToken;

  const pusherConfig = {
    clientKey: process.env.NEXT_PUBLIC_PUSHER_APP_KEY,
    cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER,
    forceTLS: true,
    authEndpoint: API_URL + "/broadcasting/auth",
    auth: {
      headers: { Authorization: `Bearer ${token}` },
    },
  }


  return <>
    <Head>
          <link rel="icon" href="/fav_ico.png" />
    </Head>
   <IntercomProvider appId={INTERCOM_APP_ID}>
    <ErrorBoundary>
      <NextNProgress
        color="#E55A34"
        startPosition={0.3}
        stopDelayMs={200}
        height={1}
        showOnShallow={true}
      />
      <PusherProvider {...pusherConfig}>
        <Component {...pageProps} />
      </PusherProvider>
    </ErrorBoundary>
    </IntercomProvider>
  </>
}

export default MyApp

MyApp.getInitialProps = async appContext => {

  const { ctx, Component } = appContext

  const appProps = await App.getInitialProps(appContext);

  let token = getTokenFromContext(ctx);


  if (Component.getServerSideProps) {
      Object.assign(appProps, await Component.getServerSideProps(ctx))
  }

  return { pageProps: { ...appProps, authToken: token } }
}
