import Cookies from 'universal-cookie';
import * as cookie from 'cookie'

import { redirect } from './Helpers';
import axios from 'axios';
const API_URL = process.env.NEXT_PUBLIC_API_URL;

export function setJWT(token : string) {
    const cookies = new Cookies();
    cookies.remove('defyreAuth');

    cookies.set('defyreAuth', token, { path: '/', maxAge: 864000 });
}

export function getJWT() {
    const cookies = new Cookies();
    return cookies.get('defyreAuth');
}

export function removeJWT() {
    const cookies = new Cookies();
    cookies.remove('defyreAuth');
}

export function checkToken(pageData) {
    if(pageData.data.message == 'Token has expired' || pageData.data.message == 'Wrong number of segments' ||  pageData.data.message == 'Token is Invalid' ||  pageData.data.message == 'Authorization Token not found') {
        return false;
    }

    return true;
}

export function getTokenFromContext(context) {
    let token = getJWT();
    if(context) {
        let cookies = context.req.headers.cookie;

        if(!cookies) {
            return false;
        }

        if(!token) {
            const parsedCookies = cookie.parse(cookies);
            token = parsedCookies.defyreAuth;  
        }

    }

    return token;
    
}


export function checkLogin( email: string, password : string, rememberMe: boolean) {
    let body = {
        email: email,
        password: password,
        rememberMe: rememberMe
    };
    return axios.post(API_URL + '/login', body);
}

